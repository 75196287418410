/* Modal Overlay */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 8, 23, .85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 50%;
  max-width: 680px;
  min-width: 400px;
  max-height: 80%;
  position: absolute;
  overflow: auto;
}

/* Modal Header */
.modal-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 1.2rem;
  }

  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    outline: none;
  }
}

/* Modal Body */
.modal-body {
  padding: 16px;
}

/* Modal Footer */
.modal-footer {
  padding: 16px;
  padding-top: 8px;
  text-align: right;

  .modal-save {
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    background: #554ef1 !important;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;

    &:hover {
      background-color: #0056b3;
      /* Hovered state background color */
    }
  }
}