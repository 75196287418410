@import '../../App.scss';
@import '../../components/Workflow.scss';

.component-list-div {
    max-width: 312px;
    width: 83%;
    padding: 16px;
    font-family: $font_family;
    border-right: solid 0.5px rgba(5, 5, 82, 0.1);
    overflow-y: scroll;
    height: 100vh;

    &__heading {
        font-size: 10px;
        font-weight: 600;
        color: #050552;
        display: flex;
        align-items: center;
    }
}

.component-list-div-children {
    width: 53%;
    width: 100%;
    // padding: 16px;
    font-family: $font_family;

    &__heading {
        font-size: 10px;
        font-weight: 600;
        color: #050552;
        display: flex;
        align-items: center;
    }
}

.component-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-left: 12px;
}

.component-add-btn {
    display: flex;
    height: 20px;
    width: 20px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
}

.sub-component-add-btn {
    @extend .component-add-btn;
    align-items: center;
    justify-content: center;
}


.sub-component-list-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background: blue; */
    border: chocolate;
    border-width: 4px;
    border: solid;
    border-radius: 10px;
    border-style: dotted;

    margin-bottom: 8px;
    font-family: $font_family;
    padding: 4px;

    &__heading {
        font-size: 10px;
        font-weight: 600;
        color: rgba(5, 5, 82, 0.6);
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
}

.sub-component-heading {
    display: flex;
    padding: 4px;
    padding-left: 12px;
    align-items: center;
}

.sub-component-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.8);
    padding: 4px;
    padding-left: 16px;
    line-height: 4px;
    font-size: 12px;
    font-weight: normal;
    font-family: $font_family;
    display: flex;
    justify-content: space-between;
    margin: 4px;


    &__active {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
        padding: 4px;
        padding-left: 16px;
        line-height: 4px;
        font-size: 12px;
        font-weight: normal;
        font-family: $font_family;
        border-radius: 5px;
        line-height: 20px;
        border: solid 0.5px rgba(5, 5, 82, 0.08);
        background-color: rgb(139 139 245 / 26%);
        display: flex;
        justify-content: space-between;
        margin: 4px;
    }

}

.component-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.8);
    padding: 4px 12px 4px 16px;
    font-weight: normal;
    font-family: $font_family;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 8px;

    &:hover {
        outline: solid 0.5px rgba(5, 5, 82, 0.08);
        background-color: rgba(5, 5, 82, 0.04);
        cursor: pointer;

        .component-name-dlt-btn {
            visibility: visible;
        }
    }

    &__active {
        @extend .component-name;
        border: solid 0.5px rgba(5, 5, 82, 0.08);
        background-color: rgb(139 139 245 / 26%);

        &:hover {
            border: solid 0.5px rgba(5, 5, 82, 0.08);
            background-color: rgb(139 139 245 / 26%);
        }

        .component-name-dlt-btn {
            visibility: visible;
        }
    }
}

.component-name-label {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
}

.component-name-dlt-btn {
    height: 12px;
    width: 12px;
    display: flex;
    align-items: center;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    visibility: hidden;

    &:hover {
        cursor: pointer;
    }
}

.master {
    display: flex;
}

.edit-properties-div {
    padding: 16px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    &__section {
        border-top: 0.5px solid rgba(5, 5, 82, 0.1);
        margin-top: 10px;

        &:last-child {
            margin-bottom: 60px;
        }
    }

    &__title {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        font-family: $font_family;
        font-size: 10px;
        font-weight: 600;
        color: #050552;
        text-transform: uppercase;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        height: 28px;

    }

    &__label {
        padding-right: 4px;
    }

    &__property {
        font-family: $font_family;
        margin-top: 8px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }

    &__dropdown {
        width: 100%;
        border-radius: 6px;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.1);
        height: 31px;
        font-family: $font_family;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
        appearance: none;
        background: url('../../assests/icons/dropDownIcon.svg') no-repeat right 14px bottom 12px;
        padding-left: 8px;

        &-container {
            margin-bottom: 8px;
        }
    }

    &__custom-dropdown {
        position: relative;

        &-input {
            @extend .edit-properties-div__dropdown;
            background: none;
            padding-left: 8px;
        }

        &-container {
            margin-bottom: 8px;
            position: relative;
        }

        &-button {
            background-color: transparent;
            border: none;
            outline: none;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-options {
            @extend .workflow_output_field_dropdown_mainList;
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        &-option {
            @extend .workflow_output_field_dropdown_liButton;
        }
    }

    &__text-input {
        width: 100%;
        height: 31px;
        padding: 8px;
        padding-right: 24px;
        border-radius: 6px;
        background-color: rgba(5, 5, 82, 0.03);
        border: solid 1px rgba(5, 5, 82, 0.03);
        font-family: $font_family;
        font-size: 12px;
        color: #050552;
        outline: none;

        &-container {
            margin-bottom: 8px;
        }

        &:focus {
            border: solid 1px #554ef1;
            background-color: none;
        }

        &:invalid {
            border: 1px solid #e10606;
        }
    }

    &__text-copy {
        all: unset;
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    &__text-container {
        position: relative;
    }

    &__alert {
        color: #554ef1;
        padding-left: 8px;
        margin-top: 2px;
        font-size: 10px;
    }

    &__number-input {
        @extend .edit-properties-div__text-input;
        border: solid 1px rgba(5, 5, 82, 0.1);
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        background-color: #fff;
        padding-right: 8px;

        &::-webkit-inner-spin-button {
            opacity: 1
        }

        &-container {
            @extend .edit-properties-div__text-input-container;
        }
    }
}

.toggle {
    margin-left: 1rem;

    label {
        display: block;
        position: relative;
        cursor: pointer;
        user-select: none;
        color: rgba(5, 5, 82, 0.8);
    }

    input {
        display: none;

        &:checked+.slider {
            background-color: #01BF83;
            ;
        }

        &:checked+.slider:before {
            background-color: #ffffff;
            transform: translateX(10.5px);
        }
    }

    .slider {
        display: flex;
        justify-content: flex-start;
        padding-left: 3px;
        align-items: center;
        width: 32px;
        height: 20px;
        border-radius: 34px;
        background-color: #fffdfd;
        border: solid 1px $night-blue-20;
        transition: background-color 0.2s;
        box-shadow: inset 0 0 2px rgba(239, 236, 236, 0.4);

        &::before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $night-blue-20;
            transition: transform 0.2s;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        }
    }

    .slider.round {
        border-radius: 34px;

        &::before {
            border-radius: 50%;
        }
    }
}

.validation-type-btn {
    height: 23px;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: $font_family;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.6);
    box-shadow: -4px 0 15px 0 rgba(5, 5, 82, 0.05);
    background-color: rgba(85, 78, 241, 0.03);
    border: none;
    cursor: pointer;

    &__active {
        border: solid 1px rgba(5, 5, 82, 0.05);
        background-color: #ffffff;
        color: #050552;
        cursor: pointer;
        box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
        border: solid 1px rgba(5, 5, 82, 0.05);
    }

}

.validation-value {
    width: 100%;
    border: none;
    font-family: $font_family;
    font-size: 12px;
    color: #050552;
    background-color: inherit;
    margin-top: 12px;

    &:focus {
        // border: solid 1px #4ef14e;
        outline: none;
        background-color: white;
    }
}

.validation-drawer-div {
    padding: 8px;
    margin-top: 8px;
    background-color: rgba(5, 5, 82, 0.03);

    border-radius: 6px;

    &:focus-within {
        outline: solid 1px #554ef1;
        background-color: white;
    }

    &__errormsg {
        width: 100%;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        font-family: $font_family;

        label {
            display: flex;
            font-size: 10px;
            font-weight: 600;
            color: rgba(5, 5, 82, 0.6);
            align-items: center;
        }

        div {
            display: flex;
            align-items: center;
        }

    }


}

.edit-settings-div {
    &__title {
        font-family: $font_family;
        font-size: 10px;
        font-weight: 600;
        color: rgba(5, 5, 82, 0.6);
        margin-bottom: 16px;
    }

    &__subtitle {
        @extend .edit-settings-div__title;
        margin-bottom: 8px;
    }

    &__dropdown-options-row {
        margin-bottom: 8px;
    }

    &__label {
        font-size: 12px;
        color: rgba(5, 5, 82, 0.8);
    }

    &__toggle-condition {
        margin-bottom: 16px;
    }

    &__validation-input {
        margin-bottom: 16px;
    }

    &__condition_button {
        background: none;
        border: none;
        font-family: $font_family;
        font-size: 12px;
        color: #554ef1;
        padding: 0;
        cursor: pointer;
    }

    &__condition_input {
        margin-top: 8px;
        font-family: $font_family;
        padding: 8px;
        border-radius: 6px;
        border: none;
        background-color: rgba(5, 5, 82, 0.03);
        width: 100%;
        color: #050552;

        &:focus {
            border: solid 1px #4ef14e;
        }
    }
}

.delete-component {
    height: 37px;
}