@import '../../../../App.scss';

.conditionalVar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    top: 0;
    left: 0%;
    z-index: 9999 !important;

    &__properties_tab {
        position: fixed;
        width: 120%;
        height: 100%;
        top: 0;
        left: -10%;
        z-index: 999999 !important;
        background-color: rgb(5 5 82 / 37%);
        backdrop-filter: blux(1px);

        &__content {
            background-color: white;
            opacity: 1;
            width: 100%;
            top: 6%;
            position: absolute;
            border-radius: 0.375rem;
            box-shadow: 0 0 0.9375rem 0 rgba(5, 5, 82, 0.1);
            padding: 12px;
        }
    }

    &__display_title {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;

        &__close {
            background-color: unset;
            border: none;
            cursor: pointer;
        }
    }

    &__display {
        font-family: $font_family;
        font-weight: 500;
        color: rgba(5, 5, 82, 0.8);
        margin-bottom: 12px;
    }

    &__add {
        background-color: unset;
        font-family: $font_family;
        font-weight: 500;
        border-radius: 6px;
        height: 31px;
        color: #554ef1;
        border: none;
        cursor: pointer;
    }

    &__div {
        display: flex;
        flex-direction: column;
    }

    &__conditions_title {
        margin-top: 12px;
        margin-bottom: 12px;
        font-family: $font_family;
        font-size: 12px;
        font-weight: 500;
        color: rgba(5, 5, 82, 0.8);
    }

    &__content {
        background-color: white;
        opacity: 1;
        width: 80%;
        height: 80vh;
        position: absolute;
        top: 10%;
        left: 10%;
        border-radius: 0.375rem;
        box-shadow: 0 0 0.9375rem 0 rgba(5, 5, 82, 0.1);
        padding: 12px;
    }

    &__name {
        font-family: $font_family;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
        margin-bottom: 12px;
        margin-top: 12px;

        &__value {
            font-family: IBMPlexMono;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            background-color: #f8f8fa;
            color: rgba(150, 38, 31, 0.8);
            margin-right: 6px;
        }

        &__input {
            font-family: $font_family;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);
            width: 204px;
            height: 31px;
            padding: 8px;
            border-radius: 6px;
            box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
            border: solid 1px rgba(5, 5, 82, 0.1);
        }
    }

    &__title {
        font-family: $font_family;
        font-size: 14px;
        font-weight: 500;
        color: #050552;

        &__div {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between
        }

        &__back {
            width: 32px;
            margin-right: 12px;
            cursor: pointer;
            border: none;
            background-color: unset;

            &_div {
                display: flex;
            }
        }

        &__create {
            color: white;
            font-family: $font_family;
            border-radius: 6px;
            height: 31px;
            background-color: #554ef1;
            border: none;
            margin-right: 12px;
            cursor: pointer;
        }

        &__delete {
            background-color: rgba(5, 5, 82, 0.05);
            height: 31px;
            width: 30px;
            padding: 10px 10px;
            cursor: pointer;
            border: none;
        }

        &__close-div {
            display: flex;
            align-items: center;
        }
    }
}

.add-rule {
    color: #554ef1;
    font-family: $font_family;
    border: none;
    background-color: unset;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.condition-var {
    &__content__body {
        margin-top: 1rem;
        font-family: $font_family;
        font-size: 0.75rem;
        font-weight: normal;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
        height: 90%;
    }
}

.conditional-var_value {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.is_true {
    font-family: $font_family;
    font-size: 12px;
    color: rgba(5, 5, 82, 0.8);
    font-weight: normal;
    margin-right: 4px;
}

.then {
    font-family: $font_family;
    font-size: 12px;
    font-weight: normal;
    color: rgba(5, 5, 82, 0.4);
    margin-right: 4px;
}

.individual_conditional_var_div {
    height: 56vh;
    overflow: scroll;
}

.add-else-if {
    height: 31px;
    border-radius: 6px;
    border: solid 1px #554ef1;
    background-color: unset;
    font-family: $font_family;
    color: #554ef1;
    font-weight: 500;
}

.conditional-variable {
    &__picker {
        position: absolute;
        height: 196px;
        padding: 12px 0;
        border-radius: 6px;
        box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
        border: solid 0.5px rgba(5, 5, 82, 0.1);
        background-color: #ffffff;

        width: 212px;
        z-index: 99999999 !important;

        &__content {
            overflow: scroll;
            height: 90%;
        }

        &__add-div {
            border: solid 0.5px rgba(5, 5, 82, 0.1);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            &:hover {
                background-color: rgba(5, 5, 82, 0.1);

            }
        }

        &__value {
            font-family: $font_family;
            padding-left: 12px;
            font-size: 12px;
            color: rgba(5, 5, 82, 0.8);
            cursor: pointer;
            line-height: 31px;

            &:hover {
                background-color: rgba(5, 5, 82, 0.1);
            }
        }
    }
}