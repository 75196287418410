@import '../../App.scss';

.edit-branding-popup-background {
    background-color: #97cef8;
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 3, 77, 0.6);
    top: 0%;
    right: 0%;
}

.edit-branding-popup {
    background-color: #fff;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0%;
    right: 0%;
    font-family: $font_family;
    overflow: hidden;

    &__header {
        &__button {
            display: flex;
            background-color: #fff;
            border: none;
            border-bottom: solid 0.5px rgba(5, 5, 82, 0.1);
            font-family: $font_family;
            height: 38px;
            align-items: flex-end;
            padding-bottom: 12px;
            min-width: 60px;
            font-size: 14px;
            color: rgba(5, 5, 82, 0.8);

            &:hover {
                background-color: #97cef8;
                cursor: pointer;
            }

            & :disabled {
                cursor: not-allowed;
                background-color: #97cef8;
            }
        }

        &-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 48px;
        }

        &__ribbon {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 250px;
            padding-left: 16px;
            border-bottom: solid 0.5px rgba(5, 5, 82, 0.1);
        }


        &__save {
            &-lang {
                margin-top: 12px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
            }

            &-close-div {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 16px;
            }

            &__button {
                background-color: #554EF1;
                color: #fff;
                border: #554EF1;
                height: 31px;
                width: 60px;
                border-radius: 5px;
                cursor: pointer;
            }

        }

        &__save__button_form {
            background-color: #554EF1;
            color: #fff;
            border: #554EF1;
            height: 31px;
            width: 5rem;
            font-size: 12px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            &:disabled {
                cursor: not-allowed;
                background-color: rgba(85, 78, 241, 0.4);
                border-color: rgba(85, 78, 241, 0.4);
            }
        }

        &__close__button {
            height: 12px;
            margin-left: 24px;
            cursor: pointer;
        }

        &__active {
            color: #554EF1;
            border-bottom: solid 1px #554EF1;
            font-weight: 500;
        }
    }

    &__body {
        display: flex;
        overflow: hidden;
        height: calc(100% - 48px);

        &__settings {
            position: absolute;
            right: 0;
            border: solid 1px rgba(5, 5, 82, 0.05);
            height: 100%;
            padding-bottom: 48px;
            width: 50.3%;
            overflow: hidden;
        }
    }

    &__no-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 16px;
    }
}

.sdk-screen {
    min-width: 30.4% !important;
    height: 100%;
}

.modules-list {
    width: 250px;
    height: 100%;
    font-family: $font_family;
    overflow: auto;

    &__heading {
        height: 38px;
        display: flex;
        text-transform: capitalize;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: rgba(5, 5, 82, 0.6);
        padding-left: 16px;
    }
}

.modules {
    display: flex;
    flex-direction: column;

    &__individual-name {
        font-family: $font_family;
        width: 100%;
        height: 40px;
        border: solid 1px rgba(5, 5, 82, 0.05);
        text-align: left;
        padding-inline: 24px 16px;
        background-color: rgba(5, 5, 82, 0.05);
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
            content: url('../../assests/icons/dropDownIcon.svg');
        }
    }
}

.screenNames {
    font-family: $font_family;
    font-size: 12px;
    height: 31px;
    background-color: #fff;
    border: none;
    text-align: left;
    padding-left: 24px;
    cursor: pointer;
    margin: 4px 8px 0;

    &:last-child {
        margin-bottom: 4px;
    }

    &:hover {
        background-color: rgba(5, 5, 82, 0.04);
        border: solid 0.5px rgba(5, 5, 82, 0.08);
        border-radius: 8px;
    }

    &_active {
        background-color: rgba(85, 78, 241, 0.1);
        border: solid 0.5px rgba(85, 78, 241, 0.2);
        border-radius: 8px;
        color: #554ef1;
        font-weight: 500;

        &:hover {
            background-color: rgba(85, 78, 241, 0.1);
            border: solid 0.5px rgba(85, 78, 241, 0.2);
            border-radius: 8px;
        }
    }
}

.screen-div {
    display: flex;
    flex-direction: column;
}

.hv-modal {
    height: 93%;
    width: 15%;
}

.edit-branding-div {
    display: flex;
}