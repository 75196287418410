@import '../../../../../App.scss';

.conditional-variable-value {
    cursor: pointer;
    display: flex;
    height: 31px;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
    border: solid 1px rgba(5, 5, 82, 0.1);
    background-color: var(--border-grey);
    width: 30%;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    justify-content: space-between;

    &__input {
        font-family: $font_family;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }
}

.conditional-var-value-dropdown {
    width: 30%;
    font-family: $font_family;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}