@import "../App.scss";
@import "../containers/Container.scss";
@import "../components/ImportWorkflow/TextInput.scss";

.import_workflow {
    &__bar {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    &__file_upload_container {
        display: flex;
        border-radius: 5px;
        margin: 3px;
        background: #f1f1f9;
        width: 100%;
        gap: 4px;
        overflow: hidden;
    }

    &__label {
        margin: 0.5em;
        font-family: Inter;
        font-weight: 600;
        font-size: 14px;
        min-width: 120px;
    }

    &__item {
        display: flex;
        height: 36px;
        width: 138px;
        gap: 8px;

        &_button {
            border-radius: 6px;
            border: solid 0.5px #554ef1;
            background-color: #554ef1;
            color: #ffffff;
            font-family: Inter;
            font-size: 13px;
            font-weight: 500;
            height: 100%;
            width: 100%;
            cursor: pointer;
        }
    }

    &__drawer {
        position: absolute;
        width: 400px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        padding: 1em;
        background: white;
    }

    &__drawer_background {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    &__button {
        padding: 8px 12px;
        border-radius: 5px;
        background: none;
        border: solid 1.2px #554ef1;
        font-size: 12px;
        text-align: left;
        color: #554ef1;
        cursor: pointer;
        font-weight: 600;

        &__primary {
            padding: 8px 12px;
            border-radius: 5px;
            border: solid 1.2px #554ef1;
            font-size: 12px;
            text-align: left;
            cursor: pointer;
            font-weight: 600;
            background: #554ef1;
            color: white;

            &:disabled {
                border: solid 1.2px #9590fa;
                display: flex;
                background: #9590fa;
                color: white;
                gap: 4px;
                cursor: not-allowed;
            }
        }
    }

    &__file_upload {
        padding: 8px 12px;
        border-radius: 5px;
        background: none;
        border: solid 1.2px #554ef1;
        font-size: 12px;
        text-align: left;
        color: #554ef1;
        cursor: pointer;
        font-weight: 600;
    }
}

.modal_module_type_heading {
    // height: 32px;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: left;
    color: rgba(5, 5, 82, 0.4);
    padding-top: 1.5em;
    text-transform: uppercase;

}

.modal_placeholder {
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: rgba(5, 5, 82, 0.8);
    transform: translateY(-10%);
}

.modal_node {
    display: flex;
    gap: 12px;
    height: 4.5em;
    align-items: center;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(5, 5, 82, 0.05);
    }

    &__icon {
        width: 40px;
        height: 40px;
        background-color: rgba(85, 78, 241, 0.1);
        margin: 0px;
        padding: 2px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        width: 40px;
        height: 40px;
        background-color: rgba(85, 78, 241, 0.1);
        margin: 0px;
        padding: 2px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__emptyIcon {
        width: 40px;
        height: 40px;
        background-color: rgba(85, 78, 241, 0.1);
        margin: 0px;
        padding: 2px;
        border-radius: 8px;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 300px;

        &__heading {
            font-size: 12px;
            display: flex;
            align-items: flex-start;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.8);

        }

        &__subheading {
            flex-grow: 1;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: left;
            color: rgba(5, 5, 82, 0.4);
        }
    }

    &__addButton {
        border: none;
        background: transparent;
        width: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-right: 12px;
        cursor: pointer;

        img {
            width: 100%;
            height: 50%;
        }
    }

}

.workflow_input_modal {

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 8, 23, .85);
        /* Dark overlay color */
        z-index: 9999;
        // display: block;
    }

    &__body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        background-color: #ffff;
        z-index: 10000;
        overflow: auto;
        width: 450px;
    }

    &__header {
        width: 100%;
        height: 54px;
        padding-inline: 1.2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.4px;
        text-align: left;
        color: #050552;
        position: relative;
        border-bottom: 1px solid rgba(5, 5, 82, 0.1);
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 1.2em;
        height: calc(100% - 94px);

        .MuiFormControlLabel-root {
            justify-content: flex-end;
        }

        &_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;

            &_save {
                @extend .workflowInput__bottomContent_button;
                background: #554ef1 !important;
                color: white !important;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 72px !important;

                &:disabled {
                    color: #f9f9f9 !important;
                    background-color: rgba(85, 78, 241, 0.4) !important;
                    border: 1px solid rgba(85, 78, 241, 0.1) !important;
                    cursor: not-allowed;
                }
            }

            &_cancel {
                @extend .workflowInput__bottomContent_button;
                width: 72px !important;
                color: rgba(5, 5, 82, 0.8) !important;
                border: solid 1px rgba(5, 5, 82, 0.2) !important;
            }
        }

    }
}

.workflow_configuration_modal {

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 1, 28, 0.8);
        backdrop-filter: blur(8px);
        z-index: 9;
    }

    &__body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        background-color: #ffff;
        z-index: 10000;
        // overflow: hidden;
        height: 640px;
        width: 780px;
    }

    &__header {
        width: 100%;
        height: 54px;
        padding-inline: 1.2em;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        font-weight: 600;
        text-align: left;
        color: #050552;
        position: relative;
        border-bottom: 1px solid rgba(5, 5, 82, 0.1);

        span {
            flex-grow: 1;
            color: #050552;
            font-size: 15px;
        }

        &_button {
            height: 30px;
            width: 60px;
            padding: 8px 16px;
            border-radius: 6px;
            background-color: #564ef1;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            margin-inline: 16px;
            cursor: pointer;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        height: calc(100% - 54px);
    }

    &__sidebar {
        width: 150px;
        border-right: 1px solid rgba(5, 5, 82, 0.1);
        padding-top: 0.5em;
        padding-inline: 0.5em;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;

        &_button {
            width: 130px;
            height: auto;
            padding: 8px;
            font-size: 12px;
            text-align: left;
            border: none;
            background: none;
            letter-spacing: 0.4px;
            color: rgba(5, 5, 82, 0.8);
            cursor: pointer;

            &_active {
                width: 130px;
                height: auto;
                padding: 8px;
                font-size: 12px;
                text-align: left;
                font-weight: 600;
                color: #554ef1;
                border: solid 0.5px rgba(85, 78, 241, 0.2);
                background-color: rgba(85, 78, 241, 0.1);
                border-radius: 6px;
                letter-spacing: normal;
            }
        }
    }

    &__maincontent {
        flex-grow: 1;
        padding-block: 12px;
        padding-inline: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow-y: auto;
    }
}

.workflowInput {
    &__topContent {
        height: 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_heading {
            font-size: 14px;
            font-weight: 500;

            color: rgba(5, 5, 82, 0.8);

        }

        &_subheading {
            font-size: 12px;

            color: rgba(5, 5, 82, 0.6);
        }
    }

    &__bottomContent {
        flex-grow: 1;

        &_body {
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            max-width: 370px;
        }

        &_button {
            padding: 8px 12px;
            border-radius: 5px;
            background: none;
            border: solid 1.2px #554ef1;
            font-size: 12px;
            text-align: left;
            color: #554ef1;
            cursor: pointer;
            font-weight: 600;
            width: 150px
        }
    }
}

.workflowOutput__bottomContent_body {
    @extend .workflowInput__bottomContent_body;
    max-width: 100% !important;
}

.workflowCondVars {
    &__topContent {
        height: 52px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_heading {
            font-size: 14px;
            font-weight: 500;

            color: rgba(5, 5, 82, 0.8);

        }

        &_subheading {
            font-size: 12px;
            color: rgba(5, 5, 82, 0.6);
        }
    }

    &__bottomContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &_body {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 6px;
            border: solid 1px rgba(5, 5, 5, 0.05);

            &__row {
                width: 100%;
                padding: 8px;
                display: flex;
                justify-content: space-between;
                position: relative;
                height: 76px;
                align-items: center;
                border-bottom: solid 1px rgba(5, 5, 5, 0.05);
            }


            &__name {
                height: 24px;
                padding: 8px;
                border-radius: 6px;
                background-color: #f8f8fa;
                font-size: 12px;
                text-align: left;
                font-weight: 600;
                color: rgba(150, 38, 31, 0.8);
                display: flex;
                align-items: center;
            }

            &__modifyBtn {
                &_container {
                    opacity: 0;
                    pointer-events: none;
                    border-radius: 6px;
                    box-shadow: 0 1px 2px 0 rgba(5, 5, 82, 0.06);
                    border: solid 1px #e1e1ee;
                    position: absolute;
                    right: 0;
                    transform: translateX(50%);
                    background-color: #ffffff;
                }

                height: 24px;
                border-radius: 6px;
                background: none;
                border: none;
                font-size: 12px;
                cursor: pointer;
                width: 24px;
                display: flex;
                gap: 4px;
                padding: 4px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &__row:hover .workflowCondVars__bottomContent_body__modifyBtn_container {
                opacity: 1;
                pointer-events: all;
                transition: opacity 0.4s ease;

            }
        }

        &_addBtn {
            padding: 8px 12px;
            border-radius: 6px;
            background: none;
            border: solid 1.2px #554ef1;
            font-size: 12px;
            text-align: left;
            color: #554ef1;
            cursor: pointer;
            font-weight: 600;
            width: 185px;
            display: flex;
            gap: 4px;
        }
    }
}

.add_node_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 28, 0.4);
    /* Dark overlay color */
    z-index: 9999999999;
    // display: block;
}

.add_node_modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 404px;
    /* Adjust the width as needed */
    height: 100%;
    border-radius: 4px;
    background-color: #ffff;
    z-index: 10000;
    overflow: auto;
}

.add_node_modal_header {
    // border: 2px solid black;
    width: 100%;
    height: 48px;
    padding-inline: 0.8em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.14px;
    text-align: left;
    color: #050552;
    position: relative;
}

.add_node_modal_content {
    // border: 2px solid black;
    width: 100%;
    // flex-grow: 1;
    padding-left: 0.8em;
    height: calc(100% - 94px);
}

::placeholder {
    color: rgba(5, 5, 82, 0.4);
    opacity: 1;
}

.add_node_modal_searchbox {
    width: 100%;
    height: 40px;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding-left: 1em;
    outline: none;
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(5, 5, 82, 0.05);
    border-bottom: 1px solid rgba(5, 5, 82, 0.05);
    border-left: none;
    border-right: none;
}

.closeModalButton {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.delete_node_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 1em;
    // width: 480px;
    /* Adjust the width as needed */
    // height: 100%;
    border-radius: 4px;
    background-color: #ffff;
    z-index: 10000;
    overflow: auto;

    // display: flex;
    // flex-direction: column;
    // transform: translateX(100%);
    // transition: transform 0.3s ease-out;
}

.generic_confirmation_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    background-color: #ffff;
    z-index: 10000;
    overflow: auto;
    width: 25%;
    min-width: 250px;
}

.generic_confirmation_modal_header {
    width: 100%;
    height: 48px;
    padding-inline: 0.8em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: "Inter";
    letter-spacing: 0.14px;
    text-align: left;
    color: #050552;
    position: relative;
}

.generic_confirmation_modal_content {
    width: 100%;
    padding: 10px 10px;
}

.generic_confirmation_modal_description {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: "Inter";
    line-height: 1.54;
    letter-spacing: normal;
    color: rgba(5, 5, 82, 0.8);
    margin: 0;
}

.generic_confirmation_modal_btn_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.react-flow__handle-top {
    top: 0 !important;
}

.react-flow__handle-bottom {
    bottom: 0 !important;
}

.react-flow__handle-left {
    left: 0 !important;
}

.react-flow__handle-right {
    right: 0 !important;
}

.default_drawer {

    &_summary_container {
        margin-bottom: 2em;
    }

    &_detail_sub_container {
        margin-top: 1em;
    }

    &_content_sub_container {
        padding-top: 16px;
        padding-bottom: 16px;
        // padding: 0;

        button {
            @extend .workflowInput__bottomContent_button;

        }
    }


    &_content__left_text {
        @extend %workflow_text_decoration;
        font-weight: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }

    &_content__right_text {
        @extend %workflow_text_decoration;
        font-weight: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.6);
    }

}

#default_drawer {

    &_workflow_id__text_container {
        white-space: nowrap;
    }

    &_workflow_id__text {
        white-space: nowrap;

        .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: #f6f7ff;
            color: #554ef1;
            text-align: center;
            border-radius: 6px;
            margin-top: 4px;
            padding: 4px 8px 5px;
            text-transform: lowercase;
        }
    }

    &_workflow_id__text:hover {
        .tooltiptext {
            visibility: visible;
        }
    }
}

.workflow_input_form_drawer {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.workflow_input_field {
    display: flex;
    gap: 16px;
    height: 36px;
    width: 100%;

    label {
        color: rgba(5, 5, 82, 0.6);
        font-size: 12px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: solid 1px rgba(5, 5, 82, 0.1);
    }

    .MuiInputBase-root {
        height: 100%;
    }

    &_textfield {
        flex-grow: 1 !important;
    }

    &_select {
        min-width: 90px;
    }

    &_deleteButton {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
    }

    .MuiInputBase-root {
        .MuiInputBase-input {
            font-size: 12px;
            color: rgba(5, 5, 82, 0.8) !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .MuiFormHelperText-root {
        margin-top: 0;
        font-size: 10px;
    }

}

.MuiButtonBase-root {
    font-size: 12px !important;
}

.workflow_output_field {
    @extend .workflow_input_field;
    position: relative;

    &_select {
        width: 340px;
    }

    &_textfield {
        @extend .workflow_input_field_textfield
    }

    &_deleteButton {
        @extend .workflow_input_field_deleteButton
    }

    &_typeSelectionDiv {
        display: flex;
        width: 25%;
    }

    &_typeSelection {
        width: 100%;
    }

    &_key {
        display: flex;
        width: 30%;
    }

    &_deleteButtonDiv {
        display: flex;
        width: 5%;
    }

    &_dropdown {
        &_button {
            @extend .workflow_input_field_deleteButton;
            position: absolute;
            transform: translateX(-200%);
            top: 25%;
        }

        &_mainList {
            position: absolute;
            border-radius: 5px;
            box-shadow: 0 5px 15px 0 rgba(5, 5, 82, 0.1);
            border: solid 0.5px rgba(5, 5, 82, 0.1);
            background-color: #ffffff;
            z-index: 100000 !important;
        }

        &_modules {
            @extend .workflow_output_field_dropdown_mainList;
            transform: translate(95%, 10px);
            max-height: 250px;
            overflow-y: scroll;
        }

        &_selectedModule {
            @extend .workflow_output_field_dropdown_mainList;
            transform: translate(195%, 10px);
            max-height: 250px;
            overflow-y: scroll;
        }

        &_conditionalVariables {
            @extend .workflow_output_field_dropdown_mainList;
            transform: translate(84%, 20px);
            max-height: 250px;
            overflow-y: scroll;
            max-width: 200px
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                padding: 0;
                margin: 0;
                min-width: 160px;
            }
        }

        &_liButton {
            font-size: 12px;
            width: 100%;
            border: none;
            background: none;
            padding-block: 10px;
            padding-inline: 16px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            &:hover {
                background-color: rgba(5, 5, 82, 0.03);
            }

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }
        }

        &_sideIcon {
            @extend .workflow_input_field_deleteButton;
        }
    }
}

.drawer {

    &_top__container {
        padding: 24px;
        display: flex;
        flex-direction: row;
        // border-bottom: 1px solid rgb(235, 235, 235);

        &_heading {
            display: flex;
            align-items: center;
        }

        &_icon {
            display: flex;
            margin-right: 12px;
            border-radius: 8px;
            border: solid 0.5px rgba(5, 5, 82, 0.1);
            background-color: rgba(5, 5, 82, 0.03);
            height: 32px;
            width: 32px;
            align-items: center;
            justify-content: center;

            &__image {
                height: 18px;
                width: 18px;
            }
        }

    }

    &_content__container {
        padding: 16px;
    }

    &_top__icon {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background-color: rgba(85, 78, 241, 0.1);
        margin: 0px;
        padding: 0px;
    }

    &_top__heading {
        @extend %workflow_text_decoration;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */
        letter-spacing: 0.01em;
        color: #050552;
    }

    &_top__subheading {
        @extend %workflow_text_decoration;
        font-weight: 300;
        text-align: left;
        color: rgba(5, 5, 82, 0.6);
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
    }

    &_top_options {
        display: flex;
    }

    &_top_divider {
        background-color: rgba(85, 78, 241, 0.1);
    }

    &_content__heading {
        @extend %workflow_text_decoration;

        font-weight: bold;
        text-align: left;

        display: flex;
        font-size: 12px;
        color: rgba(5, 5, 82, 0.4);
        align-items: center;

    }

    &_content__heading_default {
        @extend %workflow_text_decoration;
        font-weight: bold;
        text-align: left;
        color: rgba(5, 5, 82, 0.4);
        /* height: 10px; */
        font-size: 10px;
        align-items: center;
        display: flex;
    }

    &_content__subheading {
        @extend %workflow_text_decoration;
        font-weight: 300;
        line-height: 1.5;
        text-align: left;
        color: rgba(5, 5, 82, 0.6);
        margin-top: 10px;
    }

    &_content__subheading_default {
        font-size: 12px;
        font-weight: 600;
        color: rgba(5, 5, 82, 0.8);
        padding-top: 12px;
        padding-bottom: 26px;
    }

    &_content__text {
        @extend %workflow_text_decoration;
        font-weight: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.6);
    }

    &_country__list_container {
        width: 100%;
    }

    &_country__list_ul_container {
        padding: 0;
    }

    &_country__list_icon_small {
        width: 40px;
        height: 30px;
        min-width: 0px;
    }

    &_country__list_text_small {
        @extend %workflow_text_decoration;
        font-weight: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }

    &_country__list_button_small {
        padding: 0px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &_country__list_expand_text {
        @extend %workflow_text_decoration;
        text-align: left;
        color: rgba(85, 78, 241, 0.8);
    }

    &_doc__list_expand_text {
        @extend %workflow_text_decoration;
        color: rgba(85, 78, 241, 0.8);
        margin-top: 16px;
    }

    &_country__list_icon_large {
        width: 50px;
        height: 40px;
    }

    &_country__list_text_large {
        @extend %workflow_text_decoration;
        font-weight: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
    }

    &_country__list_button_large {
        padding: 0px;
        padding-bottom: 16px;
    }

    &_back__icon {
        color: #6C6B98;
    }

    &_module_output__container {
        margin-top: 24px;
    }

    &_input_box {
        width: 90%;
        height: 31px;
        margin-bottom: 16px;
        padding: 8px;
        border-radius: 3px;
        border: solid 1px rgba(85, 78, 241, 0.4);
        background-color: var(--border-grey);
        @extend %workflow_text_decoration;
        font-weight: normal;
        text-align: left;
        color: rgba(5, 5, 82, 0.6);
    }

    &_table__heading {
        @extend %workflow_text_decoration;
        text-align: left;
        color: rgba(5, 5, 82, 0.8);
        background-color: rgba(5, 5, 82, 0.03);
    }

    &_table__container {
        margin-top: 16px;
    }

    &_small_grey__text {
        @extend %workflow_text_decoration;
        font-weight: normal;
        font-size: 10px;
        text-align: left;
        color: rgba(5, 5, 82, 0.4);
    }

    &_required_input_text__container {
        margin-top: 5px;
    }

    &_info__icon {
        width: 8px;
        height: 10px;
        font-size: 8px;
        color: white;
        text-align: center;
        border-radius: 2px;
        background-color: rgba(5, 5, 82, 0.3);
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
    }

    &_tooltip__link {
        text-decoration: none;
    }

}


.custom_edge {
    &_button__container {
        border: none;
        background-color: #fff;
        // height: 100%;
        // width: 100%;
        // opacity: 0;
        // pointer-events: none;
        // transition: all 0.2s;

        // &:hover {
        //     opacity: 0.9;
        //     pointer-events: all;
        // }
    }

    &_button__img {
        height: 100%;
        width: 100%;
    }
}

.react-flow__edge-path {
    stroke: #9b9bba !important;
    cursor: default;
    pointer-events: all;
    // padding: 500px;

    // &_svg:hover {
    //     .edgebutton {
    //         background: url('../assests/icons/addIcon.svg') no-repeat;
    //         background-size: contain;
    //     }
    // }

    &:hover+.edgebutton-foreignobject .edgebutton {
        // box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
        background: url('../assests/icons/addIcon.svg') no-repeat;
        background-size: contain;
    }

    &_div {
        cursor: default;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        &:hover .edgebutton {
            background: url('../assests/icons/addIcon.svg') no-repeat;
            background-size: contain;
        }
    }
}

.edge-without-box {
    border: none;
}

.edge-with-box {
    border: 1px dashed #554ef1;
    width: 50%;
    background-color: #ffff;
    border-radius: 8px;
}

.edgebutton {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 3s ease-out;

    &:hover {
        // box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
        background: url('../assests/icons/addIcon.svg') no-repeat;
        background-size: contain;
    }
}

#condition_drawer {
    &_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        &_heading {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #050552;
        }

        >p {
            color: #00034d;
            font-size: 14px;
            font-weight: 600;
        }
    }

    &_if_block:before {
        padding: 0;
        margin: 0;
        display: block;
        /* probably not really needed? */
        content: "";
        width: 93%;
        height: 1.1px;
        background-color: white;
        position: absolute;
        right: 0;
        top: -1px;
    }

    &_if_block:after {
        padding: 0;
        margin: 0;
        display: block;
        /* probably not really needed? */
        content: "";
        width: 93%;
        height: 1.1px;
        background-color: white;
        position: absolute;
        right: 0;
        bottom: -1px;
    }

    &_if_block {
        position: relative;

        &.border-box {
            margin: 15px 0px;
            padding: 0px;
            padding-left: 20px;
            border-radius: 5px;
            border-left: 1px solid rgba(5, 5, 82, 0.1);
            ;
            border-top: 1px solid rgba(5, 5, 82, 0.1);
            border-bottom: 1px solid rgba(5, 5, 82, 0.1);
        }

        &_between {
            font-size: 12px;
        }

        &_item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: #00034d;
            font-size: 12px;
            padding: 10px 0px;
            font-style: italic;
            white-space: nowrap;

            &_value {
                padding-right: 20px !important;
            }

            >div {
                margin-right: 0px;
                padding-right: 7px;
            }
        }
    }

    &_then_block {
        margin-left: 35px;

        p {
            font-size: 12px;

            span {
                padding: 10px;
                border-radius: 5px;
                border: solid 0px #c4c4c4;
                font-weight: 600;
                margin-left: 5px;
                color: #554ef1;
                background-color: #f4f5ff;
            }
        }
    }

    &_else_block {
        margin-left: 35px;

        p {
            font-size: 12px;

            span {
                padding: 10px;
                border-radius: 5px;
                border: solid 0px #c4c4c4;
                font-weight: 600;
                margin-left: 5px;
                color: #554ef1;
                background-color: #f4f5ff;
            }
        }
    }
}

.button_edge {
    display: none;
    font-size: 13px;
    padding: 10px 7px !important;
    border-radius: 5px !important;
    font-weight: 500;
    margin-left: 5px;
    color: #554ef1;
    text-align: center;

    &.active {
        display: block;
    }
}

.active-condition {
    font-size: 10px;
    color: rgba(5, 5, 82, 0.8);
    font-family: $font_family;
}

.branch-name {
    display: none;
}

.loader {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.options-ribbon {
    display: flex;
    justify-content: space-between;
    margin-top: -24px;

    &__tab {
        width: 100%;
        background-color: #fff;
        padding: 8px;
        border: none;
        font-family: $font_family;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #050552;
        border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);

        &:hover {
            color: white;
            background-color: #554ef1;
            cursor: pointer;
            border-radius: 5px;
        }

        &:disabled {
            color: rgba(5, 5, 82, 0.4);
            cursor: not-allowed;
        }

        &:disabled:hover {
            background-color: #fff;
            color: rgba(5, 5, 82, 0.4);
            cursor: not-allowed;
        }

        &__info {
            width: 100%;
            background-color: #fff;
            padding: 8px;
            border: none;
            font-family: $font_family;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: rgba(5, 5, 82, 0.4);
            border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);

            &:hover {
                cursor: not-allowed;
            }
        }

        &__workflow_info {
            @extend .options-ribbon__tab__info;

            &:hover {
                color: white;
                background-color: #554ef1;
                cursor: pointer;
                border-radius: 5px;
            }
        }
    }



    &__configurations {
        justify-content: flex-start;
        margin-top: 0;
        border-bottom: 0.5px solid rgba(5, 5, 82, 0.1);

        &__tab {
            background-color: #fff;
            padding: 8px;
            border: none;
            font-family: $font_family;
            font-style: normal;
            font-size: 12px;
            /* identical to box height */
            color: rgba(5, 5, 82, 0.8);
        }
    }

    &__active {
        color: #554EF1;
        border-bottom: solid 1px #554EF1;
    }
}

.workflowConfigurations {
    display: flex;
    align-items: center;
    color: rgba(5, 5, 82, 0.8);
    font-size: 12px;
    font-weight: 500;
    padding-top: 12px;

    &__title {
        display: flex;
        align-items: center;
        color: rgba(5, 5, 82, 0.8);
        font-size: 14px;
        font-weight: 500;
        min-width: 120px;
    }
}

.goto-drawer {
    &__text-input-div {
        @extend .text-input-div;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 8px 4px;
    }

    &__sub_container {
        // @extend .default_drawer_content_sub_container;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }


    &__subheading {
        @extend .drawer_content__heading_default;
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: flex-start;

        &__text {
            color: rgba(5, 5, 82, 0.8);
            font-weight: 500;
            font-size: 12px;
        }
    }
}

.border-box {
    font-family: $font_family;
    margin-top: 16px;

    &__heading {
        font-family: $font_family;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgba(5, 5, 82, 0.5);

        &_goto {
            @extend .border-box__heading;
            margin-bottom: 0;
        }
    }

}

.dropdown-row {
    display: flex;
    flex-direction: row;

    &__item {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
    }

    &__label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }

    &__select {
        padding: 0.5rem;
        font-size: 1rem;
    }
}

