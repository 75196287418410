@import "../../App.scss";

$table_header_background_color: rgba(5, 5, 82, 0.06);
$table_cell_text_color: rgba(5, 5, 82, 0.8);
$table_name_cell_text_color: #050552;
$table_id_cell_text_color: rgba(5, 5, 82, 0.6);
$table_description_cell_text_color: rgb(5, 5, 82);

#workflow_table {
  &:not(.emptyLoading) {
    & td {
      padding: 16px 20px;
    }
  }
  & tr { 
    cursor: pointer;
  }
  
  &__head {
    background-color: $table_header_background_color;
    
    &--cell_name {
      width: 20%;
      font-family: $font_family;
      font-size: 14px;
      font-weight: 600;
      padding: 1.5em;
      color: $table_cell_text_color;
    }

    &--cell_description {
      width: 35%;
      font-family: $font_family;
      font-size: 14px;
      font-weight: 600;
      padding: 1.5em;
      color: $table_cell_text_color;
    }

    &--cell_modules {
      width: 45%;
      font-family: $font_family;
      font-size: 14px;
      font-weight: 600;
      padding: 1.5em;
      color: $table_cell_text_color;
    }
  }

  &__body {
    cursor: pointer;
    &:hover {
      background-color: rgba(85, 78, 241, 0.03);

      #workflow_table__body--workflow_id {
        color: rgba(5, 5, 82, 0.6);
      }
      #workflow_table__body--workflow_copy {
        opacity: 1;
      }
    }

    > * {border: 0;}

    &--workflow {
      width: 20%;
      padding: 1.45em;
      white-space: nowrap;
      font-family: $font_family;
      
      &_name {
        &_container {
          display: flex;
          flex-direction: column;
        }
        font-size: 14px;
        font-weight: 500;
        color: $table_name_cell_text_color;
        margin: 0;
      }

      &_id_container {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        margin-top: 5px;
      }

      &_id {
        font-size: 12px;
        font-weight: 300;
        color: $table_id_cell_text_color;
        padding: 0;
        margin: 0;
      }

      &_copy {
        opacity: 0;
        cursor: pointer;
        font-size: 0.8em;
        color: #554ef1;
        padding: 0.4em 1.2em;
        border-radius: 2em;
        background-color: rgba(85, 78, 241, 0.05);
        margin: 0;
        margin-left: 8px;
        &.visible { 
          opacity: 1;
        }
      }
    }

    &--description {
      width: 35%;
      padding: 1.5em;
      font-family: $font_family;
      font-size: 14px;
      line-height: 1.29;
      color: $table_description_cell_text_color;
    }

    &--modules {
      padding: 1.5em;
      height: fit-content;
    }

  }

  &__modules {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-transform: lowercase;
    border-radius: 5px;

    p {
      margin: 0.6em;
      width: fit-content;
      display: inline-block;
      padding: 0.6em 1.2em;
      border-radius: 5px;
      color: #00b4c8;
      border: solid 1px rgba(0, 180, 200, 0.1);
    }
  }
}
